<template>
    <div class="invalid-feedback" v-if="errors.has(field)">
        <span v-for="error in errors.get(field)" :key="error">
            {{ error }} <br>
        </span>
    </div>
</template>

<script>

export default {
  props: ['errors', 'field'],
};
</script>
