<template>
    <div class="form-group">
        <label :for="field" v-if="label">{{ label }}</label>
        <Multiselect
                :id="field"
                :options="options"
                :track-by="trackBy"
                :disabled="disabled"
                :label="labelField"
                :allow-empty="allowEmpty"
                :custom-label="customLabel"
                :deselect-label="deselectLabel"
                :select-label="selectLabel"
                :selected-label="selectedLabel"
                :preserve-search="preserveSearch"
                @open="$emit('open', $event)"
                @input="$emit('input', $event)"
                @search-change="$emit('search-change', $event)"
                :value="value"
                :placeholder="placeholder"
                :loading="loading"
                :class="{
                     'is-invalid': errors && errors.has(field)
                 }"
                :multiple="multiple"
        >
            <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                <slot :name="name" v-bind="slotData" />
            </template>
            <template slot="noOptions">Type to search</template>
        </Multiselect>
        <AppError v-if="errors" :errors="errors" :field="field"/>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import AppError from './AppError';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
  components: {
    AppError,
    Multiselect,
  },
  props: {
    errors: {},
    label: {},
    value: {},
    field: {},
    options: {},
    multiple: {},
    trackBy: {},
    labelField: {},
    customLabel: {},
    placeholder: {},
    loading: {},
    allowEmpty: { default: false },
    deselectLabel: { default: 'Remove' },
    selectLabel: { default: '' },
    selectedLabel: { default: '' },
    disabled: { default: false },
    preserveSearch: { default: true },
  },
};
</script>
